import { useCallback, useState, useEffect } from 'react'
import {
  ResourceList,
  ResourceItem,
  Text,
  Layout,
  Page,
  Card,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
  Box,
  Banner,
  Filters,
  InlineStack
} from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useProductionsList } from '../hooks/useProductionsList'

export const ProductionListView = () => {
  const { t } = useTranslation()
  const [showBanner, setShowBanner] = useState(true)
  const {
    productions,
    currentPageProductions,
    isLoading,
    error,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    handleFiltersQueryChange,
    queryValue,
    isFreePlan
  } = useProductionsList()
  const navigate = useNavigate()

  useEffect(() => {
    if (isFreePlan) navigate('/plan-selection')
  }, [isFreePlan, navigate])

  const handleCreate = useCallback(() => {
    navigate('/productions/new')
  }, [navigate])

  const emptyStateMarkup = (
    <EmptyState
      heading={t('productions.createProductionsToGetStarted')}
      action={{ content: t('productions.createProductions'), onAction: handleCreate }}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
      <p>
        {t('productions.contactSupportToUploadProductions')}
      </p>
    </EmptyState>
  )

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonDisplayText size="large" />
              <BlockStack gap='200'>
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    )
  }

  if (error) {
    return (
      <Page title={t('productions.productions')}>
        <Card>
          <Text variant="bodyMd" color="critical">{t('productions.error')}: {error}</Text>
        </Card>
      </Page>
    )
  }

  return (
    <Page
      title={t('productions.productions')}
      primaryAction={{
        content: t('productions.createProduction'),
        onAction: handleCreate
      }}
      divider
    >
      {showBanner && (
        <div>
          <Box paddingBlock='400'>
            <Banner
              title={t('banner.beta')}
              onDismiss={() => setShowBanner(false)}
              tone='info'
            >
            </Banner>
          </Box>
        </div>
      )}
      <Card>
        <Layout>
          <Layout.Section>
            <Card>
              <ResourceList
                filterControl={
                  <Filters
                    filters={[]}
                    queryValue={queryValue}
                    onQueryChange={handleFiltersQueryChange}
                  />
                }
                emptyState={productions.length === 0 ? emptyStateMarkup : undefined}
                resourceName={{ singular: t('productions.production'), plural: t('productions.productions') }}
                items={currentPageProductions}
                renderItem={(item) => {
                  const productionId = item.production_id
                  const productionQuantity = item.output_quantity
                  const productionDate = item.production_date
                  const outputProductName = item.output_product.product_name
                  const productVariant = item.output_product.product_variant_name

                  return (
                    <ResourceItem
                      id={productionId}
                      onClick={() => {
                        navigate(`/productions/${productionId}`)
                      }}
                      accessibilityLabel={`${t('productions.viewDetailsFor')} ${outputProductName}`}
                    >
                      <InlineStack gap='200' blockAlign="center" align="space-between">
                        <Box>
                          <Text variant="bodyMd" fontWeight="bold" as="h6">
                            {outputProductName}
                          </Text>
                          <div>{productVariant}</div>
                        </Box>
                        <InlineStack gap='1600'>
                          <Text variant="headingXs" as="h6">
                            {Math.round(productionQuantity)}
                          </Text>
                          <Text variant="headingXs" as="h6">
                            {productionDate.substring(0, 10)}
                          </Text>
                        </InlineStack>
                      </InlineStack>
                    </ResourceItem>
                  )
                }}
                pagination={{
                  hasNext: hasNextPage,
                  onNext: handleNextPage,
                  hasPrevious: hasPreviousPage,
                  onPrevious: handlePreviousPage
                }}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Card>
    </Page>
  )
}
