import { useState, useCallback, useEffect, useContext } from 'react'
import { DataUpdateContext } from '../../../shared/context/context'
import { fetchBomDetails, updateBom, deleteBom } from '../../../shared/services/bomService'
import { fetchProducts } from '../../../shared/services/productsService'
import { objectToId } from '../../../shared/utils'
import { DEFAULT_BOM_STATUS } from '../utils/constants'

export const useBomDetails = (initialBomId, setIsSaving, setForbiddenMaterialId, forbiddenProductIds, setForbiddenProductIds) => {
  const [bom, setBom] = useState({ materials: [], status: DEFAULT_BOM_STATUS })
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [showOutputProductRequiredError, setShowOutputProductRequiredError] = useState(false)
  const [showMaterialRequiredError, setShowMaterialRequiredError] = useState(false)
  const [error, setError] = useState(null)
  const [bomId, setBomId] = useState(initialBomId)
  const [showStatusBanner, setShowStatusBanner] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const { companyId, appBridge, isAppBridgeReady, jobStatus } = useContext(DataUpdateContext)

  const fetchData = useCallback(async () => {
    const promises = []

    setIsLoading(true)
    setError(null)

    if (!bomId || !companyId || !isAppBridgeReady) return

    try {
      promises.push(fetchProducts(companyId, appBridge))
      if (bomId !== 'new') promises.push(fetchBomDetails(bomId, appBridge))
      const [productsData, bomData] = await Promise.all(promises)
      if (bomId !== 'new') setBom(bomData)
      setProducts(productsData)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }, [bomId, companyId, appBridge, isAppBridgeReady])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setIsSaveButtonDisabled(!bom.output_product || !(bom.materials?.length > 0) || bom.materials.some(material => !material.quantity || isNaN(material.quantity)))
    setShowMaterialRequiredError(!(bom.materials?.length > 0) && bom.bom_id)
  }, [bom])

  useEffect(() => {
    switch (jobStatus) {
      case 'failed':
        setShowBanner(false)
        setShowErrorBanner(true)
        break
      case 'succeeded':
        setShowBanner(false)
        setShowErrorBanner(false)
        break
      case 'in_progress':
        setShowBanner(true)
        break
      default:
    }
  }, [jobStatus])

  const prepareBomDataToSave = useCallback(() => {
    return {
      bom_id: bom.bom_id,
      status: bom.status,
      company_id: companyId,
      output_quantity: bom.output_product_quantity || 1,
      output_product_id: bom.output_product_id,
      output_product: {
        inventory_type: 'finished_product'
      },
      materials: bom.materials.map(material => ({
        company_id: companyId,
        material_id: material.material_id,
        product_id: material.product.product_id,
        quantity: +material.quantity,
        product: {
          inventory_type: 'material'
        }
      }))
    }
  }, [bom, companyId])

  const handleDelete = useCallback(async () => {
    setError(null)

    try {
      await deleteBom(bomId, appBridge)
      return true
    } catch (err) {
      setError(err.message)
      return false
    }
  }, [bomId, appBridge])

  const handleDeleteIfNeeded = useCallback(async (bomDataToSave) => {
    if (bomDataToSave.bom_id !== bomId && bomId !== 'new') {
      await handleDelete(bomId)
    }
  }, [handleDelete, bomId])

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    setError(null)

    try {
      const bomDataToSave = prepareBomDataToSave()
      await handleDeleteIfNeeded(bomDataToSave)
      const updatedBom = await updateBom(bomDataToSave, appBridge)
      setBomId(updatedBom.bom_id)
      return updatedBom.bom_id
    } catch (err) {
      setError(err.message)
    } finally {
      setIsSaving(false)
    }
  }, [appBridge, handleDeleteIfNeeded, prepareBomDataToSave, setBomId, setIsSaving])

  const handleQuantityChange = useCallback((materialId, newQuantity) => {
    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.map(m =>
        m.material_id === materialId ? { ...m, quantity: parseInt(newQuantity, 10) } : m
      )
    }))
  }, [])

  const handleOutputProductSave = useCallback(([newProduct]) => {
    if (!newProduct) return

    const newBomId = objectToId({ outputProductId: newProduct.product_id })

    if (bom.status === 'active') setShowStatusBanner(true)

    setBom(prevBom => ({
      ...prevBom,
      bom_id: newBomId,
      output_product_id: newProduct.product_id,
      output_product: {
        inventory_type: 'finished_product',
        product_name: newProduct.product_name,
        product_variant_name: newProduct.product_variant_name
      },
      materials: prevBom.materials.map(material => ({
        ...material,
        company_id: companyId,
        material_id: objectToId({ bomId: newBomId, productId: material.product.product_id })
      }))
    }))
  }, [companyId, bom.status])

  const handleRemoveMaterial = useCallback((materialId, productId) => {
    setForbiddenProductIds([...forbiddenProductIds].filter((forbiddenProductId) => forbiddenProductId !== productId))

    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.filter(m => m.material_id !== materialId)
    }))
  }, [forbiddenProductIds, setForbiddenProductIds])

  const handleRemoveOutputProduct = useCallback(() => {
    setForbiddenMaterialId([])

    setBom(prevBom => ({
      ...prevBom,
      bom_id: undefined,
      output_product_id: undefined,
      output_product: undefined
    }))

    setShowOutputProductRequiredError(true)
  }, [setForbiddenMaterialId])

  const handleMaterialModalSave = useCallback((newMaterials) => {
    if (!companyId) return
    if (!bom?.materials?.length) setShowOutputProductRequiredError(true)

    setForbiddenProductIds([
      ...new Set([
        ...forbiddenProductIds,
        ...newMaterials.map(newMaterial => newMaterial.product_id)
      ])
    ])

    setBom(prevBom => ({
      ...prevBom,
      materials: [
        ...prevBom.materials,
        ...newMaterials.map(material => ({
          company_id: companyId,
          material_id: objectToId({ productId: material.product_id, bomId: prevBom.bom_id }),
          product: material,
          quantity: 1,
          product_id: material.product_id
        }))
      ]
    }))
  }, [companyId, bom, forbiddenProductIds, setForbiddenProductIds])

  const handleStatusChange = useCallback((status) => {
    status === 'active' ? setShowStatusBanner(true) : setShowStatusBanner(false)
    setBom(prevBom => ({
      ...prevBom,
      status
    }))
  }, [])

  return {
    bom,
    products,
    isLoading,
    isSaveButtonDisabled,
    error,
    handleSave,
    handleQuantityChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    handleRemoveMaterial,
    handleMaterialModalSave,
    handleDelete,
    handleStatusChange,
    showStatusBanner,
    setShowStatusBanner,
    showOutputProductRequiredError,
    showMaterialRequiredError,
    showBanner,
    showErrorBanner
  }
}
