import { backendRequest } from '../utils/axiosInstance'

export const fetchProductionsList = async (companyId, limit, offset, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/productions?company_id=${companyId}&limit=${limit}&skip=${offset}&populate=output_product&sort=-production_date`,
      data: {},
      appBridge
    })
    return response.data
  } catch (error) {
    throw new Error('Error fetching productions\' list: ' + error.message)
  }
}
