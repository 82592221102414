import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import ShopifyLayout from './shared/layouts/ShopifyLayout'
import './App.css'
import { DataUpdateContext, DataUpdateProvider } from './shared/context/context'
import ErrorPage from './shared/components/ErrorPage'
import { BundleListView } from './apps/bom/views/BundleListView'
import { BundleDetailsView } from './apps/bom/views/BundleDetailsView'
import { PlanSelectionView } from './apps/bom/views/PlanSelectionView'
import { ShopifySubcriptionReturn } from './shared/views/ShopifySubcriptionReturn'
import { WelcomeView } from './apps/bom/views/WelcomeView'
import { ProductionListView } from './apps/bom/views/ProductionListView'

function App () {
  return (
    <div className='App'>
      <DataUpdateProvider>
        <RouteManager />
      </DataUpdateProvider>
    </div>
  )
}

function RouteManager () {
  const { isLogin, landingUrl } = useContext(DataUpdateContext)

  if (isLogin === false) {
    return <ErrorPage errorCode='401' />
  }

  return (
    <Router>
      <Routes>
        <Route path='/shopify/subscription/return' element={<ShopifySubcriptionReturn navigateUrl='/welcome'/>} />
        <Route path='*' element={<Navigate to={landingUrl} replace />} />
        <Route path='/bundles' element={<ShopifyLayout><BundleListView /></ShopifyLayout>} />
        <Route path='/bundles/:id' element={<ShopifyLayout><BundleDetailsView /></ShopifyLayout>} />
        <Route path='/productions' element={<ShopifyLayout><ProductionListView /></ShopifyLayout>} />
        {/* <Route path='/productions' element={<ShopifyLayout><ProductionDetailsView /></ShopifyLayout>} /> */}
        <Route path='/plan-selection' element={<ShopifyLayout><PlanSelectionView /></ShopifyLayout>} />
        <Route path='/welcome' element={<ShopifyLayout><WelcomeView /></ShopifyLayout>} />
        <Route path='/401' element={<ErrorPage errorCode='401' />} />
        <Route path='/404' element={<ErrorPage errorCode='404' />} />
        <Route path='/500' element={<ErrorPage errorCode='500' />} />
      </Routes>
    </Router>
  )
}

export default App
