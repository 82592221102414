import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { backendRequest } from '../../../shared/utils/axiosInstance'
import { useTranslation } from 'react-i18next'
import '../../../shared/styles/dashboard.css'
import { DataUpdateContext } from '../../../shared/context/context'
import { useNavigate } from 'react-router-dom'
import { addWeeks, format } from 'date-fns'
import { AgGridTable } from '../components/common/AGGridTable'
import { currencyFormatter, formatter } from '../../../shared/utils/formatter'
import { setDefaultFilterSelectedValues } from '../../../shared/utils'
import { Box, InlineGrid } from '@shopify/polaris'
import { InventoryCard } from '../../replen/components/InventoryCard'
import {
  LOCATION_NAME_DEFAULT_NOT_IN_FILTER,
  PRODUCT_STATUS_DEFAULT_NOT_IN_FILTER,
  INVENTORY_TRACKED_DEFAULT_NOT_IN_FILTER,
  REPLENISHMENT_NOW_DAY_THRESHOLD,
  DAILY_VELOCITY_THRESHOLD,
  REPLENISHMENT_SOON_DAY_THRESHOLD
} from '../../../shared/utils/constant'

const DashboardView = () => {
  const navigate = useNavigate()

  const openReplenishmentView = (filter) => {
    navigate('/replenishment', { state: { filter } })
  }

  const gridApi = useRef(null)
  const { t } = useTranslation()
  const [replenishSoonData, setReplenishSoonData] = useState(null)
  const [replenishNowData, setReplenishNowData] = useState(null)
  const [overStockData, setOverStockData] = useState(null)
  const [deadStockData, setDeadStockData] = useState(null)
  const [weekColumns, setWeekColumns] = useState([])
  const [data, setData] = useState({
    pivotColumns: [],
    metrics: []
  })
  const { lastUpdate, companyId, companyParams, setThresholdsRequestHeaders } = useContext(DataUpdateContext)

  const onGridReady = useCallback(async (params) => {
    gridApi.current = params.api
    gridApi.current.onFilterChanged()
  }, [gridApi])

  const autoGroupColumnDef = useMemo(() => {
    return {
      filter: 'agGroupColumnFilter',
      pinned: 'left',
      cellRendererParams: {
        suppressCount: true
      }
    }
  }, [])

  useEffect(() => {
    if (!lastUpdate || !companyParams) {
      // wait for lastUpdate be loaded
      return
    }
    const params = {
      fields: 'product_name,product_variant_name,product_sku,is_material,product_supplier,location_name,inventory_status,is_inventory_tracked,product_status',
      inventory_status: 'replenish_now,replenish_soon,overstock,deadstock',
      populate: 'product,location',
      pivotColumns: 'metric_name',
      pivotValue: 'metric_value',
      metric_name: 'day_inventory,inventory_value,daily_velocity,loss_sales,inventory_quantity,quantity_to_order,next_order_date',
      metric_date: lastUpdate.split('T')[0],
      company_id: companyId,
      sort: 'next_order_date,-loss_sales'
    }
    const queryParam = new URLSearchParams(params)
    backendRequest({
      method: 'GET',
      url: `/obius/metrics?${queryParam.toString()}`,
      requestHeaders: (setThresholdsRequestHeaders(companyParams))
    })
      .then((response) => {
        const { metrics } = response.data
        const prepareCartData = (filter, aggregationColumn) => {
          const data = metrics.filter((
            {
              inventory_status: inventoryStatus,
              product_status: productStatus,
              is_inventory_tracked: isInventoryTracked,
              location_name: locationName
            }) => (
            inventoryStatus === filter &&
            !PRODUCT_STATUS_DEFAULT_NOT_IN_FILTER.includes(productStatus) &&
            !INVENTORY_TRACKED_DEFAULT_NOT_IN_FILTER.includes(isInventoryTracked) &&
            !LOCATION_NAME_DEFAULT_NOT_IN_FILTER.includes(locationName)
          ))
          const total = data.reduce((acc, { [aggregationColumn]: value = 0 }) => acc + +value, 0)
          return {
            total_products: data.length,
            total_sell_price: total,
            total_inventory_value: total,
            product_list: data,
            column: aggregationColumn
          }
        }
        if (metrics) {
          setReplenishNowData(prepareCartData('replenish_now', 'loss_sales'))
          setReplenishSoonData(prepareCartData('replenish_soon', 'loss_sales'))
          setOverStockData(prepareCartData('overstock', 'inventory_value'))
          setDeadStockData(prepareCartData('deadstock', 'inventory_value'))
        }
      })
      .catch((error) => {
        console.error('Error fetching \'Replenish\' data:', error)
      })

    const tenWeeksAgo = addWeeks(new Date(), -10).toISOString().split('T')[0]
    const fetchMetricData = async () => {
      const params = new URLSearchParams({
        fields: 'location_name,metric_name',
        populate: 'location',
        pivotColumns: 'period_label',
        pivotValue: 'metric_value',
        'metric_date>': tenWeeksAgo,
        '!product_id': '',
        '!status': '',
        company_id: companyId,
        metric_name: 'low_stock_losses,out_of_stock_losses,inventory_value,weekly_sales,inventory_turnover'
      })
      try {
        const response = await backendRequest({
          method: 'GET',
          url: `/obius/metrics?${params}`,
          requestHeaders: setThresholdsRequestHeaders(companyParams)
        })
        const { metrics, pivotColumns } = response.data
        if (!metrics) {
          return
        }
        setWeekColumns(pivotColumns)
        setData(metrics)
      } catch (error) {
        console.error('Error fetching metric data', error)
      }
    }

    fetchMetricData()
  }, [lastUpdate, companyId, companyParams, setThresholdsRequestHeaders])

  const onFirstDataRendered = async () => {
    await setDefaultFilterSelectedValues({ filterName: 'location_name', unselectedValues: LOCATION_NAME_DEFAULT_NOT_IN_FILTER, gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'metric_name', unselectedValues: [null], gridApi })
    gridApi.current.onFilterChanged()
  }

  const initialGroupOrderComparator = useCallback(
    (params) => {
      const order = [
        'out_of_stock_losses',
        'low_stock_losses',
        'weekly_sales',
        'inventory_value',
        'inventory_turnover'
      ]
      const a = params.nodeA.key || ''
      const b = params.nodeB.key || ''
      return order.indexOf(a) < order.indexOf(b) ? -1 : order.indexOf(a) > order.indexOf(b) ? 1 : 0
    },
    []
  )

  const generateWeekColumns = useCallback(() => {
    return weekColumns.map(week => ({
      headerName: week,
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        return p.data && +p.data[week]
      },
      filterParams: {
        defaultOption: 'greaterThan'
      },
      aggFunc: 'sum',
      width: 133,
      valueFormatter: (params) => {
        return isNaN(params.value) ? 0 : (params.node?.key === 'inventory_turnover' || params.node?.parent?.key === 'inventory_turnover') ? formatter.format(params.value) : currencyFormatter.format(params.value)
      }
    }))
  }, [weekColumns])

  //
  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('kpi.title'),
        field: 'metric_name',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        pinned: 'left',
        valueFormatter: ({ value }) => t(`kpi.${value}`),
        cellRendererParams: {
          suppressCount: true
        }
      },
      {
        headerName: t('product.location'),
        field: 'location_name',
        filter: 'agSetColumnFilter'
      },
      ...generateWeekColumns()
    ]

    return baseColumns
  }, [generateWeekColumns, t])

  const openModalSoon = () => {
    if (replenishSoonData.product_list.length > 0) {
      openReplenishmentView('replenish_soon')
    }
  }
  const openModalnow = () => {
    if (replenishNowData.product_list.length > 0) {
      openReplenishmentView('replenish_now')
    }
  }
  const openModalOver = () => {
    if (overStockData.product_list.length > 0) {
      openReplenishmentView('overstock')
    }
  }
  const openModalDead = () => {
    if (deadStockData.product_list.length > 0) {
      openReplenishmentView('deadstock')
    }
  }
  const isValidNumber = (number) => number !== null && number >= 0
  return (
    <div className='dashboard-container'>
      <div>
        <div className='dashboard-title'>
          <div>
            <h1>{t('sidebar.dashboard')}</h1>
            <h2 className='subtitle is-6 mt-1'>{t('common.updated')} {format(new Date(lastUpdate), 'yyyy-MM-dd H:mm')}</h2>
          </div>
        </div>
        <div className='dashboard-actions' />
        <Box padding='500'>
          <InlineGrid columns={5} gap='800'>
            {replenishNowData && (
              <InventoryCard
                title={t('dashboard.replenish_now')}
                totalSellPrice={replenishNowData.total_sell_price}
                totalProducts={replenishNowData.total_products}
                description={t('dashboard.replenish_now')}
                tone='critical'
                onCardClick={openModalnow}
                tooltipStatus={t('tooltip.replenish_now')}
                tooltipVars={
                  [
                    isValidNumber(companyParams.replenishNowDayThreshold) ? companyParams.replenishNowDayThreshold : REPLENISHMENT_NOW_DAY_THRESHOLD,
                    isValidNumber(companyParams.dailyVelocityThreshold) ? companyParams.dailyVelocityThreshold : DAILY_VELOCITY_THRESHOLD
                  ]
                }
              />
            )}
            {replenishSoonData && (
              <InventoryCard
                title={t('dashboard.replenish_soon')}
                totalSellPrice={replenishSoonData.total_sell_price}
                totalProducts={replenishSoonData.total_products}
                description={t('dashboard.replenish_soon')}
                tone='caution'
                onCardClick={openModalSoon}
                tooltipStatus={t('tooltip.replenish_soon')}
                tooltipVars={[companyParams.replenishSoonDayThreshold || REPLENISHMENT_SOON_DAY_THRESHOLD, companyParams.dailyVelocityThreshold || DAILY_VELOCITY_THRESHOLD]}
              />
            )}
            {overStockData && (
              <InventoryCard
                title={t('dashboard.overstock')}
                totalProducts={overStockData.total_products}
                description={t('dashboard.overstock')}
                tone='magic'
                onCardClick={openModalOver}
                tooltipStatus={t('tooltip.overstock')}
              />
            )}
            {deadStockData && (
              <InventoryCard
                title={t('dashboard.deadstock')}
                totalProducts={deadStockData.total_products}
                description={t('dashboard.deadstock')}
                tone='magic'
                onCardClick={openModalDead}
                tooltipStatus={t('tooltip.deadstock')}
              />
            )}
          </InlineGrid>
        </Box>
      </div>

      <div className='items-section obius-grid-size-m'>
        {data.length > 0 && (
          <AgGridTable
            rowData={data}
            colDefs={columns}
            onFirstDataRendered={onFirstDataRendered}
            initialGroupOrderComparator={initialGroupOrderComparator}
            onGridReady={onGridReady}
            autoGroupColumnDef={autoGroupColumnDef}
          />
        )}
      </div>
    </div>
  )
}

export default DashboardView
